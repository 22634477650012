import {
  DrupalVideoMedia,
} from "../../types/hygiena-types";
import {HTMLProps, useContext, useEffect, useState} from "react";
import {DrupalMedia} from "next-drupal";
import classNames from "classnames";
import {MediaImage} from "../templates/media/media--image";
import {absoluteURL} from "../../lib/absolute-url";
import {BlurMapContext} from "../../context/blur-map-context";
import Image from "next/image";
import {HERO_IMAGE_STYLE, HERO_PLACE_HOLDER_URL} from "../../types/image-styles";
import {WidgetVideofader} from "./widget--videofader";

export const HERO_MAX_WIDTH = "1920px";
// export const HERO_IMAGE_SIZES = "(min-width: 1920px) 1920px, (min-width: 1440px) 1440px, (min-width: 1024px) 1024px, 90vw";
export const HERO_IMAGE_SIZES = "100vw";

/**
 * Returns a hero image placeholder.
 *
 * @param altText
 * @constructor
 */
export function PlaceHolderHeroImage({altText, className}: {altText?: string, className?: string}) {
  const blurMap = useContext(BlurMapContext);

  return (
    <Image
      src={absoluteURL(HERO_PLACE_HOLDER_URL)}
      blurDataURL={blurMap?.hero_placeholder?.base64}
      placeholder={blurMap?.hero_placeholder?.base64 ? "blur" : "empty"}
      sizes={HERO_IMAGE_SIZES}
      width={1000}
      height={600}
      className={classNames("absolute inset-0 w-full h-full", className ?? "")} style={{objectFit: "cover", height: "100%", width: "100%"}}
      alt={altText ?? "Hygiena"}/>
  );
}

interface HeroProps extends Partial<HTMLProps<HTMLDivElement>> {
  className?: string,
  image?: any,
  altText?: string,
  video?: DrupalVideoMedia | DrupalVideoMedia[],
  small?: boolean,
  collapsed?: boolean,
}

/**
 * The generic hero component.
 *
 * @param className
 *   The classname.
 * @param image
 *   The image.
 * @param altText
 *   The alt text.
 * @param video
 *   The video.
 * @param small
 *   A shorter height hero.
 * @param collapsed
 *   Will display a smaller height hero if true.
 * @param props
 *   Additional props.
 * @constructor
 */
export function Hero({className, image, altText, video, small, collapsed, ...props}: HeroProps): JSX.Element {
  const [smallScreen, setSmallScreen] = useState<boolean>();
  const [beginVideo, setBeginVideo] = useState<boolean>(false);

  // We use the video URL if there is only one video being passed, if the video is an array, we
  // apply a fader.
  let videoFile;
  if (Array.isArray(video) && video?.length === 1) {
    videoFile = video?.[0]?.field_media_file;
  }
  else if (!Array.isArray(video) && video?.field_media_file) {
    videoFile = video.field_media_file;
  }
  const videoUrl = videoFile?.uri?.url
    ? absoluteURL(videoFile?.uri?.url)
    : undefined;

  useEffect(() => {
    if (typeof window === "undefined") return;
    const setSize = () => setSmallScreen(window.innerWidth < 768);
    setSize();
    const loadVideo = () => !beginVideo && setBeginVideo(true);
    window.addEventListener("resize", setSize);
    window.addEventListener("mousemove", loadVideo);

    return () => {
      window.removeEventListener("resize", setSize);
      window.removeEventListener("mousemove", loadVideo);
    }
  }, []);

  return (
    <div className={classNames(
      "w-full px-0 relative 2xl:max-w-[1920px] 2xl:mx-auto flex md:mb-12",
      {
        "md:h-[480px] lg:h-[720px]": !small && !collapsed,
        "md:min-h-[50vh]": !small && collapsed,
        "md:min-h-[480px] 2xl:max-h-[480px]": small,
      },
      className
    )} {...props}>
      {(!videoUrl && !video?.length) && (image?.image || image?.bf_image) ? (
        <MediaImage
          priority
          loading={"eager"}
          imageStyle={HERO_IMAGE_STYLE}
          media={image}
          key={`hero-image--${image.id}`}
          sizes={HERO_IMAGE_SIZES}
          className={"absolute inset-0 overflow-hidden"} style={{objectFit: "cover", height: "100%", width: "100%", maxWidth: HERO_MAX_WIDTH, margin: "auto"}}/>
      ): (
        <>{(!videoUrl && !video?.length) && <PlaceHolderHeroImage altText={altText} />}</>
      )}
      {videoUrl && (
        <div className="h-full w-full absolute">
          {(image?.image || image?.bf_image) && (
            <MediaImage
              priority
              loading={"eager"}
              imageStyle={HERO_IMAGE_STYLE}
              media={image}
              key={`hero-image--${image.id}`}
              sizes={HERO_IMAGE_SIZES}
              className={"absolute inset-0 overflow-hidden"} style={{objectFit: "cover", height: "100%", width: "100%", maxWidth: HERO_MAX_WIDTH, margin: "auto"}}/>
          )}
          {beginVideo && !smallScreen && (
            <video muted loop autoPlay className="max-md:hidden object-cover h-full w-full absolute">
              <source src={videoUrl} type="video/mp4"/>
            </video>
          )}
        </div>
      )}
      {(!videoUrl && video?.length > 1) && (
        <div className="h-full w-full absolute">
          {(image?.image || image?.bf_image) && (
            <MediaImage
              priority
              loading={"eager"}
              imageStyle={HERO_IMAGE_STYLE}
              media={image}
              key={`hero-image--${image.id}`}
              sizes={`(min-width: 1024px) 1024px, 600px`}
              className={"absolute inset-0 overflow-hidden"} style={{objectFit: "cover", height: "100%", width: "100%", maxWidth: HERO_MAX_WIDTH, margin: "auto"}}/>
          )}
          {beginVideo && !smallScreen && (
            <WidgetVideofader videos={video as DrupalVideoMedia[]} />
          )}
        </div>
      )}
      <div className={classNames(
        "container mx-auto min-h-fit flex justify-center items-center leading-8 md:px-0 2xl:px-0",
        {"py-20": !collapsed},
      )}>
        {props.children}
      </div>
    </div>
  )
}
