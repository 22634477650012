import {DrupalVideoMedia} from "../../types/hygiena-types";
import {useEffect, useState, createRef} from "react";
import {absoluteURL} from "../../lib/absolute-url";
import classNames from "classnames";

/**
 * Loops one video after another in succession to one another.
 *
 * @param videos
 * @constructor
 */
export function WidgetVideofader({videos} : {videos: DrupalVideoMedia[]}) {
  const [active, setActive] = useState(0);
  const refs = Array.from({length: videos?.length ?? 0}, () => createRef<HTMLVideoElement>())

  useEffect(() => {
    for (const ref of refs) {
      if (!ref?.current) return;
    }

    const handler = () => {
      if (!refs?.[active]?.current) return;
      const nextActive = active < videos?.length - 1 ? active + 1 : 0;
      const activeRef = refs?.[active]?.current;
      const nextRef = refs?.[nextActive]?.current;
      activeRef.currentTime = 0;
      activeRef?.play();
      nextRef?.play();
      setActive(nextActive);
    };

    // Change the video on ended.
    for (const ref of refs) {
      ref?.current?.addEventListener("ended", handler);
    }
    return () => {
      for (const ref of refs) {
        ref?.current?.removeEventListener("ended", handler);
      }
    }
  });

  return (
    <>
      {videos?.map((item, index) => {
        const isActive = active === index;
        const videoUrl = item?.field_media_file?.uri?.url
          ? absoluteURL(item?.field_media_file?.uri?.url)
          : undefined;
        return (
          <video muted autoPlay={isActive} loop={false}
                 preload={"none"}
                 className={classNames("object-cover h-full w-full absolute transition-[all] duration-[2s] max-md:hidden", {"opacity-1": isActive, "opacity-0": !isActive})}
                 ref={refs?.[index]} key={`video-fader--${videoUrl}`}>
            <source src={videoUrl} type="video/mp4" />
          </video>
        )
      })}
    </>
  )
}